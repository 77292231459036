import {
  AppShell,
  Button,
  Container,
  createStyles,
  Grid,
  Header,
  Title,
  Text,
} from "@mantine/core";
import { IconCopyright } from "@tabler/icons-react";
import { useSession, signIn, signOut } from "next-auth/react";
import Link from "next/link";
import Router from "next/router";
import * as Sentry from "@sentry/nextjs";

const useStyles = createStyles(({ breakpoints, colorScheme }) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rightSide: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  brandName: {
    [`@media (max-width: ${breakpoints.md}px)`]: {
      fontSize: 18,
    },
  },
  footer: {
    backgroundColor:
      colorScheme === "light" ? "rgba(0,0,0,0.04)" : "rgba(0,0,0,0.15)",
  },
}));

const Wrapper = ({ children, fullWidthContainer = false }) => {
  const { classes } = useStyles();
  const { data: session } = useSession();

  return (
    <AppShell
      padding={0}
      navbar={
        <Header height="60" p="xs" withBorder={false}>
          <Container className={classes.container}>
            <Title className={classes.brandName} order={1} color="spotifyGreen">
              <Link href="/">Daily Playlist</Link>
            </Title>
            <div className={classes.rightSide}>
              {session && (
                <>
                  <Button
                    onClick={() => Router.push("/dashboard")}
                    ml="lg"
                    color="spotifyGreen"
                    variant="subtle"
                  >
                    Dashboard
                  </Button>
                  <Button
                    onClick={() => Router.push("/upgrade")}
                    ml="lg"
                    color="spotifyGreen"
                    variant="subtle"
                  >
                    Upgrade
                  </Button>
                </>
              )}
              {!session && (
                <Button
                  id="submit"
                  color="primary"
                  variant="filled"
                  type="button"
                  onClick={() =>
                    signIn("spotify", {
                      callbackUrl: `/dashboard`,
                    })
                  }
                >
                  Sign in with Spotify
                </Button>
              )}
              {session && (
                <Button
                  ml="lg"
                  onClick={() => {
                    Sentry.setUser({ id: null });
                    signOut({
                      callbackUrl: "/",
                    });
                  }}
                >
                  Logout
                </Button>
              )}
            </div>
          </Container>
        </Header>
      }
      footer={
        <footer className={classes.footer}>
          <Container>
            <Grid>
              <Grid.Col md={12}>
                <Button
                  color="spotifyGreen"
                  variant="subtle"
                  onClick={() => Router.push("/contact")}
                >
                  Contact
                </Button>
              </Grid.Col>
            </Grid>
            <Text color="gray" size={14}>
              <IconCopyright /> 2023 Daily Playlist
            </Text>
          </Container>
        </footer>
      }
    >
      {fullWidthContainer ? children : <Container>{children}</Container>}
    </AppShell>
  );
};

export default Wrapper;
