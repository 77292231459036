import "../App.css";

import Head from "next/head";
import { MantineProvider } from "@mantine/core";
import { SessionProvider } from "next-auth/react";
import { useEffect } from "react";
import Script from "next/script";
import { NotificationsProvider } from "@mantine/notifications";

import { emotionCache } from "../emotion-cache";
import Wrapper from "../components/Wrapper";
import { pageview } from "../utils/gtag";
import { useRouter } from "next/router";

export function MyApp(props) {
  const {
    Component,
    pageProps: { session, ...pageProps },
  } = props;
  const router = useRouter();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Daily Playlist - Spotify® Playlist Generator</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Script
        strategy="afterInteractive"
        src="https://www.google.com/recaptcha/api.js"
      />
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-YEP4ZC4CV4"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-YEP4ZC4CV4', {
        page_path: window.location.pathname,
        });
      `,
        }}
      />
      <div>
        <SessionProvider session={session}>
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            emotionCache={emotionCache}
            theme={{
              /** Put your mantine theme override here */
              colorScheme: "light",
              headings: {
                fontFamily: ["SVN-Gilroy"].join(","),
                fontWeight: "bold",
              },
              fontSizes: { xs: 18, md: 18, lg: 18, xl: 18 },
              fontFamily: [
                "Inter",
                "Avenir",
                "Helvetica",
                "Arial",
                "sans-serif",
              ].join(","),
              colors: {
                spotifyGreen: [
                  "#e8f8ee",
                  "#d2f1dd",
                  "#bbeacc",
                  "#a5e3bb",
                  "#8edcaa",
                  "#77d598",
                  "#61ce87",
                  "#4ac776",
                  "#34c065",
                  "#1DB954",
                ],
                blueGray: [
                  "#ffffff",
                  "#eaeaec",
                  "#c1c0c6",
                  "#acabb3",
                  "#9797a0",
                  "#82828d",
                  "#6d6d7a",
                  "#595867",
                  "#444354",
                  "#2f2e41",
                ],
              },
              primaryShade: { light: 9, dark: 6 },
              primaryColor: "spotifyGreen",
              defaultRadius: 10,
            }}
          >
            <NotificationsProvider>
              <Wrapper fullWidthContainer={router.pathname === "/"}>
                <Component {...pageProps} />
              </Wrapper>
            </NotificationsProvider>
          </MantineProvider>
        </SessionProvider>
      </div>
    </>
  );
}

export default MyApp;
